import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "F:/web/active/infoamazonia-mercurio/src/components/reports/report-layout.js";
import Image from "!url-loader!../../images/fotos/DSC07021.jpg";
import MapGeorgetown from "../../images/minimaps/reports/minimap-guyana-georgetown.svg";
export const meta = {
  name: "guyana",
  title: "The Toxic Metal in the Shadow of the Gold Industry",
  intro: "Despite its health and environmental risk, mercury is widely used in gold mining, and to miners in the Amazon, the silvery liquid is almost as valuable as the gold itself.",
  by: "By Bram Ebus (text and photos) and IG Sutherland (text). Videos by Tom Laffay",
  image: Image,
  next: {
    slug: "suriname",
    title: "The Gold Rush threatening the World's greenest country",
    description: "Suriname’s waterways and trade winds carry mercury, a toxic metal used in gold extraction, to regions far removed from the country’s gold mines."
  }
};
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Video = makeShortcode("Video");
const Sidenote = makeShortcode("Sidenote");
const Gallery = makeShortcode("Gallery");
const layoutProps = {
  meta,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <p><strong parentName="p">{`It’s late in Corriverton,`}</strong>{` a small village on the western bank of the Corentyne River, the waterway that separates Guyana from Suriname. But a few local boatmen, who shuttle people and contraband across the river, are still hanging around in front of the village brothel, drinking beers and smoking weed while chatting with the sex workers who’ve come out for the night. Most of the women are Venezuelan refugees who fled the crisis in their home country or were trafficked abroad.`}</p>
    <p>{`In a dark alley next to the brothel, Showtime* smokes a cigarette and watches over a few of the women. The native Guyanese spent years living and working in the United States, where he claims to have run a group of sex workers. Back in Guyana, he's still involved in the sex industry, but his main business now is moving contraband across the river.`}</p>
    <p>{`“It’s never going to be a problem to take your shit over there,” he says, pointing east toward the river and Suriname. His thick golden necklace skims the top of his rounded belly as he leans back casually against the wall. Law enforcement is always one step behind. “Don’t matter what they do,” he says. “They got to build a fence in the motherfucking river.”`}</p>
    <Video url="https://youtube.com/embed/fqPOWU235qI" mdxType="Video" />
    <p>{`Two commodities that are frequently taken over illegally are gold and mercury, and in the Guiana Shield region, one does not go without the other. Gold is Guyana’s primary export product thanks in large part to mercury, the toxic quicksilver used in its extraction process. In 2015, Guyana produced 19.1 tonnes of gold, according to official records, requiring an estimated `}<a parentName="p" {...{
        "href": "https://www.iucn.nl/-files/groene_economie/regional_mercury_report_guyana.pdf"
      }}>{`29 tonnes of mercury`}</a>{`. Both numbers are most likely much higher now, as gold production has increased since 2015 and a significant amount of Guyanese gold is trafficked abroad.`}</p>
    <p>{`All of that mercury has grave impacts on human health and the environment, but efforts so far to curtail its use in the gold industry have only pushed supply chains underground, leaving many miners exposed to both the dire health consequences of the toxic substance and the legal risks of participating in the black market.`}</p>
    <hr></hr>
    <Sidenote side="left" mdxType="Sidenote">
	<MapGeorgetown mdxType="MapGeorgetown" />
    </Sidenote>
    <p>{`Guyana and Suriname share the same Amazon ecosystems, access to the Northern Atlantic Ocean, and colonial past. Both countries endured centuries of British and Dutch occupations, only becoming independent in the 20th century.`}</p>
    <iframe src="https://datawrapper.dwcdn.net/xz5Yz/2/"></iframe>
    <p>{`Guyana is an especially poverty stricken nation. Plagued by corruption and underdevelopment, the collateral damage of its long colonial history, the gross income per capita amounts to just $4,760 U.S. dollars a year. Nearly a third of the country’s total population of 780,000 live in the capital city of Georgetown, where thousands of families live in buildings desperately in need of repair. Dilapidated wooden houses and urban junkyards are not an uncommon sight.  But economists are optimistic about the country’s future.`}</p>
    <Video url="https://youtube.com/embed/GtkqArJG52Y" mdxType="Video" />
    <p>{`Guyana is on the brink of becoming one of the wealthiest countries in Latin America. Massive offshore oil deposits were found in 2015 and will
bring in about 1.2 million barrels a day in the near future.`}</p>
    <p>{`While oil is the talk of the town in Georgetown, the country’s 20,000 miners do not feel represented by their politicians.`}</p>
    <p>{`In 2013, Guyana signed the Minamata Convention on Mercury, a global treaty designed to reduce mercury emissions to protect human health
and the environment. Named for a city in Japan where residents were poisoned by mercury released from a local factory, the convention calls for a ban on the use of mercury for mining. Guyana ratified the convention in 2014, and committed to reducing mercury use down to 75 percent of baseline levels by 2027.`}</p>
    <blockquote>
      <p parentName="blockquote">{`“We gotta get this silver to get the gold,” says one experienced miner in the interior of Guyana.`}</p>
    </blockquote>
    <p>{`Newell Dennison, commissioner of the Guyana Geology and Mines Commission (GGMC), says that the first steps have been taken: “We’re already in the field going out to clustered areas,” where the GGMC discusses alternative mining techniques with the miners themselves. About 980 miners have received demonstrations, surveys, or workshops already, but their decision to continue with mercury or not so far depends on its effectiveness. “A lot of what’s going on is determined by what friends do, and what other miners do,” adds Dennison. “We recognize that it is not only about the GGMC going out there and doing things … it’s also about a culture shift.”`}</p>
    <p>{`In the forests of Guyana, the upcoming mercury ban is met with discontent by communities of artisanal and small-scale miners who fear for their livelihoods as their gold production depends on the availability of mercury—also known as quicksilver, or to many miners, just “silver.”`}</p>
    <Gallery mdxType="Gallery">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAIBBAX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAABynrvECkf/8QAGBAAAwEBAAAAAAAAAAAAAAAAAAECERD/2gAIAQEAAQUCV2bY3QnzD//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAEDAQE/AYj/xAAWEQADAAAAAAAAAAAAAAAAAAABEBH/2gAIAQIBAT8BpX//xAAXEAADAQAAAAAAAAAAAAAAAAAAIDIx/9oACAEBAAY/AqLNT//EABsQAAICAwEAAAAAAAAAAAAAAAARASEQcYGh/9oACAEBAAE/IRpkufQxbdwJmx//2gAMAwEAAgADAAAAEAc//8QAFhEAAwAAAAAAAAAAAAAAAAAAEBFR/9oACAEDAQE/EEgf/8QAFxEBAAMAAAAAAAAAAAAAAAAAAAERUf/aAAgBAgEBPxCdFv/EAB0QAQACAgIDAAAAAAAAAAAAAAEAESExUWFxgaH/2gAIAQEAAT8QBWvakPyPFfkbd1yqJRg9x23TyRz3n//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/1e663f43f784184f2980b8410879b2ed/e93cc/slider1-1.webp 300w", "/static/1e663f43f784184f2980b8410879b2ed/b0544/slider1-1.webp 600w", "/static/1e663f43f784184f2980b8410879b2ed/68fc1/slider1-1.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/1e663f43f784184f2980b8410879b2ed/73b64/slider1-1.jpg 300w", "/static/1e663f43f784184f2980b8410879b2ed/3ad8d/slider1-1.jpg 600w", "/static/1e663f43f784184f2980b8410879b2ed/6c738/slider1-1.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/1e663f43f784184f2980b8410879b2ed/6c738/slider1-1.jpg",
              "alt": "The upcoming mercury ban is met with discontent by communities of artisanal and small-scale miners",
              "title": "The upcoming mercury ban is met with discontent by communities of artisanal and small-scale miners",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span><em parentName="p">{`The upcoming mercury ban is met with discontent by communities of artisanal and small-scale miners`}</em></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAUBAgP/xAAWAQEBAQAAAAAAAAAAAAAAAAABAAL/2gAMAwEAAhADEAAAAVcGc1GZl//EABsQAAICAwEAAAAAAAAAAAAAAAABAjEDERIh/9oACAEBAAEFAt+OiaRZDHHn/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQMBAT8BR//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAECAQE/AVf/xAAVEAEBAAAAAAAAAAAAAAAAAAARIP/aAAgBAQAGPwIr/8QAGxAAAwACAwAAAAAAAAAAAAAAAAERMWEhUYH/2gAIAQEAAT8h5JUexsU909FkojLom0//2gAMAwEAAgADAAAAEAPP/8QAFREBAQAAAAAAAAAAAAAAAAAAERD/2gAIAQMBAT8QWP/EABYRAQEBAAAAAAAAAAAAAAAAAAEAEf/aAAgBAgEBPxDMZN//xAAbEAEAAgIDAAAAAAAAAAAAAAABABEhMUGRsf/aAAgBAQABPxCrpAwrbGBZXJbbhcMND7OToS8BVn//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/abe20d9c565ffe84554a700779109812/e93cc/slider1-2.webp 300w", "/static/abe20d9c565ffe84554a700779109812/b0544/slider1-2.webp 600w", "/static/abe20d9c565ffe84554a700779109812/68fc1/slider1-2.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/abe20d9c565ffe84554a700779109812/73b64/slider1-2.jpg 300w", "/static/abe20d9c565ffe84554a700779109812/3ad8d/slider1-2.jpg 600w", "/static/abe20d9c565ffe84554a700779109812/6c738/slider1-2.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/abe20d9c565ffe84554a700779109812/6c738/slider1-2.jpg",
              "alt": "Alt",
              "title": "Alt",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAwAE/8QAFgEBAQEAAAAAAAAAAAAAAAAAAAID/9oADAMBAAIQAxAAAAEVJs7zyR//xAAYEAADAQEAAAAAAAAAAAAAAAAAAQISA//aAAgBAQABBQJc0KZRdTpTo3Rk/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGRAAAwEBAQAAAAAAAAAAAAAAAAEhAhEi/9oACAEBAAY/AroWm/I6hs52FP/EABkQAQEBAQEBAAAAAAAAAAAAAAERADEhQf/aAAgBAQABPyEAvqMn3WaK65bdG40nV9w4chJNK307/9oADAMBAAIAAwAAABDr/wD/xAAWEQADAAAAAAAAAAAAAAAAAAAAARH/2gAIAQMBAT8QjIz/xAAWEQADAAAAAAAAAAAAAAAAAAAAARH/2gAIAQIBAT8QqKj/xAAbEAEBAAMBAQEAAAAAAAAAAAABEQAhMWFBcf/aAAgBAQABPxCVBC1NGOC7TNqM8CYVwJCP3KY1qOs9yB204J+YrbZ7Jn//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/4d34b06fd77ff2bf238ccced3b8a55e6/e93cc/slider1-3.webp 300w", "/static/4d34b06fd77ff2bf238ccced3b8a55e6/b0544/slider1-3.webp 600w", "/static/4d34b06fd77ff2bf238ccced3b8a55e6/68fc1/slider1-3.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/4d34b06fd77ff2bf238ccced3b8a55e6/73b64/slider1-3.jpg 300w", "/static/4d34b06fd77ff2bf238ccced3b8a55e6/3ad8d/slider1-3.jpg 600w", "/static/4d34b06fd77ff2bf238ccced3b8a55e6/6c738/slider1-3.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/4d34b06fd77ff2bf238ccced3b8a55e6/6c738/slider1-3.jpg",
              "alt": "Alt",
              "title": "Alt",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMBBP/EABYBAQEBAAAAAAAAAAAAAAAAAAIAAf/aAAwDAQACEAMQAAAB4VJham2//8QAGRABAQEAAwAAAAAAAAAAAAAAAgMBABES/9oACAEBAAEFAr51WUnV0m5rzj4Ggtu93//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAECAQE/ASf/xAAbEAADAAIDAAAAAAAAAAAAAAAAAREhQQISYf/aAAgBAQAGPwKNZ1CKYRH1PXs5ZK2f/8QAGxABAAMAAwEAAAAAAAAAAAAAAQARITFBYZH/2gAIAQEAAT8hfSgKLI3UOyk5J6vJ3r+2UFuNwW+T/9oADAMBAAIAAwAAABBbz//EABcRAAMBAAAAAAAAAAAAAAAAAAABETH/2gAIAQMBAT8QdWFZ/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQARIf/aAAgBAgEBPxABywv/xAAcEAEBAAIDAQEAAAAAAAAAAAABEQAhMUFhsZH/2gAIAQEAAT8Qg0Ku8O+9ZfOZ6N1PcNC0Kp1+YaBDOzQn377gayBBKFOGd4yAlZGf/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/a230acc338c77b1d6d56942ce2389e28/e93cc/slider1-4.webp 300w", "/static/a230acc338c77b1d6d56942ce2389e28/b0544/slider1-4.webp 600w", "/static/a230acc338c77b1d6d56942ce2389e28/68fc1/slider1-4.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/a230acc338c77b1d6d56942ce2389e28/73b64/slider1-4.jpg 300w", "/static/a230acc338c77b1d6d56942ce2389e28/3ad8d/slider1-4.jpg 600w", "/static/a230acc338c77b1d6d56942ce2389e28/6c738/slider1-4.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/a230acc338c77b1d6d56942ce2389e28/6c738/slider1-4.jpg",
              "alt": "Alt",
              "title": "Alt",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAABAABA//EABYBAQEBAAAAAAAAAAAAAAAAAAIBA//aAAwDAQACEAMQAAABF2ZubHLjf//EABkQAAMBAQEAAAAAAAAAAAAAAAABAhMDEv/aAAgBAQABBQLIXJGJmhRLfmT/xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAwEBPwGH/8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQIBAT8Bp//EABgQAAMBAQAAAAAAAAAAAAAAAAAQMQIh/9oACAEBAAY/AquaUIf/xAAaEAACAwEBAAAAAAAAAAAAAAAAAREhMUGR/9oACAEBAAE/IcRwIa81jVuaRJu/R5fA0VE//9oADAMBAAIAAwAAABAn/wD/xAAXEQEBAQEAAAAAAAAAAAAAAAABABFR/9oACAEDAQE/EAFhy//EABcRAQEBAQAAAAAAAAAAAAAAAAEAEVH/2gAIAQIBAT8QU2vb/8QAGxABAQEAAgMAAAAAAAAAAAAAAREAITFBYZH/2gAIAQEAAT8QeCF7Ka9UBUgGskPrgz3b6xoU+S3Pg+zv/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/2a90523be06c260163442a74d8eef870/e93cc/slider1-6.webp 300w", "/static/2a90523be06c260163442a74d8eef870/b0544/slider1-6.webp 600w", "/static/2a90523be06c260163442a74d8eef870/68fc1/slider1-6.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/2a90523be06c260163442a74d8eef870/73b64/slider1-6.jpg 300w", "/static/2a90523be06c260163442a74d8eef870/3ad8d/slider1-6.jpg 600w", "/static/2a90523be06c260163442a74d8eef870/6c738/slider1-6.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/2a90523be06c260163442a74d8eef870/6c738/slider1-6.jpg",
              "alt": "Alt",
              "title": "Alt",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAECBP/EABYBAQEBAAAAAAAAAAAAAAAAAAEAAv/aAAwDAQACEAMQAAAByZYrKxk//8QAGhAAAgMBAQAAAAAAAAAAAAAAABEBAhIDIf/aAAgBAQABBQK9+ScGWQjJ7B//xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAIAQMBAT8BkY//xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAgEBPwEn/8QAGRAAAgMBAAAAAAAAAAAAAAAAADEBESEQ/9oACAEBAAY/Aloiy53jP//EABwQAAICAgMAAAAAAAAAAAAAAAERAEEhUWGBof/aAAgBAQABPyE0iMt6jWiPLhK7qAhFooLPyA4Gn//aAAwDAQACAAMAAAAQRC//xAAWEQADAAAAAAAAAAAAAAAAAAABEBH/2gAIAQMBAT8QgE//xAAWEQEBAQAAAAAAAAAAAAAAAAABACH/2gAIAQIBAT8QAYW3/8QAGxABAAMAAwEAAAAAAAAAAAAAAQARITFBUXH/2gAIAQEAAT8QYWnV8F2h7K1teMInbn1qoYChosPszQS9og2lE//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/bd30245abf35761063e8e4e2b12eb9d6/e93cc/slider1-7.webp 300w", "/static/bd30245abf35761063e8e4e2b12eb9d6/b0544/slider1-7.webp 600w", "/static/bd30245abf35761063e8e4e2b12eb9d6/68fc1/slider1-7.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/bd30245abf35761063e8e4e2b12eb9d6/73b64/slider1-7.jpg 300w", "/static/bd30245abf35761063e8e4e2b12eb9d6/3ad8d/slider1-7.jpg 600w", "/static/bd30245abf35761063e8e4e2b12eb9d6/6c738/slider1-7.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/bd30245abf35761063e8e4e2b12eb9d6/6c738/slider1-7.jpg",
              "alt": "Alt",
              "title": "Alt",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAEEAgP/xAAVAQEBAAAAAAAAAAAAAAAAAAACA//aAAwDAQACEAMQAAABly+kaQjGf//EABkQAAMBAQEAAAAAAAAAAAAAAAABAhESIf/aAAgBAQABBQJlT51gntMqFv8A/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGhAAAQUBAAAAAAAAAAAAAAAAAQARICExQf/aAAgBAQAGPwLBa4Gj/8QAGhABAAMBAQEAAAAAAAAAAAAAAQARITFBUf/aAAgBAQABPyG7mGLekUbh4iIyHgStcJ92f//aAAwDAQACAAMAAAAQvN//xAAXEQADAQAAAAAAAAAAAAAAAAAAAREh/9oACAEDAQE/EFo6f//EABcRAQEBAQAAAAAAAAAAAAAAAAEAESH/2gAIAQIBAT8QVOQGX//EABwQAQEAAgIDAAAAAAAAAAAAAAERADEhQWFxgf/aAAgBAQABPxCkO1F5AHq4aDgQdx5PZiE+4ihaJmgFdwxCaLn/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/a7e2bd72220307dd97a62c3eb58954d5/e93cc/slider1-8.webp 300w", "/static/a7e2bd72220307dd97a62c3eb58954d5/b0544/slider1-8.webp 600w", "/static/a7e2bd72220307dd97a62c3eb58954d5/68fc1/slider1-8.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/a7e2bd72220307dd97a62c3eb58954d5/73b64/slider1-8.jpg 300w", "/static/a7e2bd72220307dd97a62c3eb58954d5/3ad8d/slider1-8.jpg 600w", "/static/a7e2bd72220307dd97a62c3eb58954d5/6c738/slider1-8.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/a7e2bd72220307dd97a62c3eb58954d5/6c738/slider1-8.jpg",
              "alt": "Alt",
              "title": "Alt",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMCBAX/xAAVAQEBAAAAAAAAAAAAAAAAAAACA//aAAwDAQACEAMQAAAByZtrzooWMf/EABkQAQEAAwEAAAAAAAAAAAAAAAIAARARMf/aAAgBAQABBQIyGjjk/b//xAAWEQADAAAAAAAAAAAAAAAAAAABEDH/2gAIAQMBAT8BgX//xAAVEQEBAAAAAAAAAAAAAAAAAAAQQf/aAAgBAgEBPwGn/8QAFxAAAwEAAAAAAAAAAAAAAAAAAAEQMf/aAAgBAQAGPwI2O//EABwQAAICAgMAAAAAAAAAAAAAAAERACEQQTFRkf/aAAgBAQABPyFa5ugIUWWJ1CFT8gi2XcZizeP/2gAMAwEAAgADAAAAENjP/8QAFhEBAQEAAAAAAAAAAAAAAAAAARAR/9oACAEDAQE/EENJ/8QAFhEBAQEAAAAAAAAAAAAAAAAAEQAB/9oACAECAQE/EM1EX//EAB0QAQEAAQQDAAAAAAAAAAAAAAERACExQVFxgaH/2gAIAQEAAT8Q3CJUDK5ROG8vB37wmJ0NYpcGktS9JoYQBm6rWHH3Fuf/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/cccefa22028a624723d3e02a2f0e0477/e93cc/slider1-9.webp 300w", "/static/cccefa22028a624723d3e02a2f0e0477/b0544/slider1-9.webp 600w", "/static/cccefa22028a624723d3e02a2f0e0477/68fc1/slider1-9.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/cccefa22028a624723d3e02a2f0e0477/73b64/slider1-9.jpg 300w", "/static/cccefa22028a624723d3e02a2f0e0477/3ad8d/slider1-9.jpg 600w", "/static/cccefa22028a624723d3e02a2f0e0477/6c738/slider1-9.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/cccefa22028a624723d3e02a2f0e0477/6c738/slider1-9.jpg",
              "alt": "Alt",
              "title": "Alt",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMFAQT/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAv/aAAwDAQACEAMQAAABXwUkRWFUP//EABkQAAMBAQEAAAAAAAAAAAAAAAECEQMAEv/aAAgBAQABBQKtorpD4YhkhzF37//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAEDAQE/AYj/xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAIAQIBAT8BjX//xAAcEAABAwUAAAAAAAAAAAAAAAABABAiAhESIUH/2gAIAQEABj8CkWjSbLRWJ43/xAAbEAEBAAIDAQAAAAAAAAAAAAABABEhMUFhcf/aAAgBAQABPyEw2SXxgZ1Hbc9gxttNvtm//9oADAMBAAIAAwAAABCHP//EABYRAAMAAAAAAAAAAAAAAAAAAAEQEf/aAAgBAwEBPxAwn//EABcRAQADAAAAAAAAAAAAAAAAAAABEVH/2gAIAQIBAT8Qni7/xAAcEAEAAwACAwAAAAAAAAAAAAABABEhMVFBYXH/2gAIAQEAAT8QQiFUml+59Bw5mcrBVbHOnMDwdSw4q16QZhh1P//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/592d3f32de4ad415d251170532370a2f/e93cc/slider1-10.webp 300w", "/static/592d3f32de4ad415d251170532370a2f/b0544/slider1-10.webp 600w", "/static/592d3f32de4ad415d251170532370a2f/68fc1/slider1-10.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/592d3f32de4ad415d251170532370a2f/73b64/slider1-10.jpg 300w", "/static/592d3f32de4ad415d251170532370a2f/3ad8d/slider1-10.jpg 600w", "/static/592d3f32de4ad415d251170532370a2f/6c738/slider1-10.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/592d3f32de4ad415d251170532370a2f/6c738/slider1-10.jpg",
              "alt": "Alt",
              "title": "Alt",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
    </Gallery>
    <p>{`In the runup to this year’s presidential election in March, won by opposition candidate Irfaan Ali after a protracted disaster with the vote count, the Guyana Gold and Diamond Miners Association (GGDMA) and the National Mining Syndicate (NMS) published a `}<a parentName="p" {...{
        "href": "https://ggdma.com/pressnews/a-manifesto-for-mining/"
      }}>{`list of demands`}</a>{` for politicians seeking the organizations’ political support. The first was that before phasing out the use of mercury, the government must provide miners with affordable alternative technologies, along with the financial and technical assistance needed to use them.`}</p>
    <p>{`Without more assistance, little will change when the prohibition kicks in, according to the miners. Mercury will still be available on the black market—for an increased price.
“If it goes underground the mercury gets more expensive, far more expensive, because it’s illegal,” says Kennard Williams*, a mine operator. Mercury traders in charge of the supply will get rich, he says, while the miners do “all the hard work.”
Gabriel Lall, the former chairman of the Guyana Gold Board, the state agency that manages the gold trade and buys and exports gold, agrees that the ban on mercury is likely to “empower the proliferation of criminal enterprises.”`}</p>
    <blockquote>
      <p parentName="blockquote">{`“OUR BORDERS ARE LONG AND POROUS, OUR OFFICERS—WITH DUE RESPECT TO THE FEW THAT ARE CLEAN—ARE K NOWN TO LOOK THE OTHER WAY FOR A PRICE,”`}</p>
    </blockquote>
    <p>{`There is widespread corruption in Guyana, Lall warns, and law enforcement officers often turn a blind eye to the illicit trade in return for kickbacks. “Our borders are long and porous, our officers—with due respect to the few that are clean—are known to look the other way for a price,” he says. “If in any link of the chain of oversight you have weaknesses, then you might as well not have a chain.” The police did not respond to a request for comment.`}</p>
    <hr></hr>
    <p>{`Guyana is the only country in the Guiana Shield region that still imports licensed mercury. Between 2008 and 2013, yearly imports averaged around 70,000 kilograms, but after Guyana signed the Minimata Convention, signaling its support for a phase out of (and future ban on) mercury, imports crashed, averaging just over 20,000 kg in the years since. Most of the mercury, which comes mainly from Russia, the United Kingdom, India, the United States, and Turkey, entered Guyana through the Georgetown seaport.`}</p>
    <iframe title="Mercury imports in Guyana (2008-2018)&amp;nbsp;" aria-label="Column Chart" src="//datawrapper.dwcdn.net/Wqe1W/1/" scrolling="no" frameBorder="0" style={{
      "border": "none"
    }} width="600" height="400"></iframe>
    <p>{`There are huge profits to be made in the trade. The retail price of mercury in Guyana can amount to more than 10 times the import price. On average, mercury is imported for $17.40 USD/kg. Wholesalers sell it for $126, but in mining areas the quicksilver goes over the counter for between $159 and $234.`}</p>
    <p>{`Although the ban is not yet in effect, talking about mercury is already taboo for the licensed importers. None of them agreed to give an interview on the record, but one former importer, who stockpiled enough mercury to continue selling it on the national market, suggests that bribes are being paid to obtain the licenses, which are managed by Guyana’s Pesticide and Toxic Chemicals Control Board. “You see, you start to get into deeper politics,” he says, grinning while clasping his hands in front of his shop’s counter in Georgetown. (The Board did not comment on the bribery allegations, but claims it has “no information to substantiate the alleged trafficking of mercury into Guyana.") He answers each question carefully, quietly weighing each response before he speaks. But when asked whether all imports are still legal, he reacts quickly: “Jesus Christ, you know you have not even touched the tip of the iceberg,” the Georgetown businessman laughs. “People are smuggling it right now into this country.”`}</p>
    <p>{`The Georgetown trader, three experts, and additional sources from within the sector claim that —in addition to the legal imports—Chinese traders are funneling illicit mercury into the Georgetown seaport.`}</p>
    <Video url="https://youtube.com/embed/lJuft01OY1E" mdxType="Video" />
    <p>{`The Chinese importers have a lot of sway with Guyana’s government, according to a Guyanese source working for the state. "These guys have
access,” he says. “These guys feel comfortable twisting the arm for calling in favours for which they pay very, very richly."`}</p>
    <blockquote>
      <p parentName="blockquote">{`The ease with which mercury moves into and around Guyana has made the country a gateway for channeling the quicksilver into neighbouring countries`}</p>
    </blockquote>
    <p>{`Once it’s inside Guyana, there’s no restrictions on the trade or movement of mercury around the country. The ease with which mercury moves into and around Guyana has made the country a gateway for channeling the quicksilver into neighbouring countries, according to Guyana’s former environmental minister Raphael Trotman. “I’m certain about the cross border trafficking,” he wrote via WhatsApp. “Most of the information I've received speaks of Suriname, but I can't rule out the others.”`}</p>
    <hr></hr>
    <p>{`In the center of Georgetown, small combi-vans pick up passengers and take them to all the corners of the country. The vehicles, painted with flashy colors and designs, aggressively zigzag through the city’s traffic, honking at even the slightest provocation. Many of the vans are bound for remote jungle outposts.`}</p>
    <p>{`One driver, headed for Lethem, a town on the border with Brazil, offers to shuttle mercury there—for a price. “Everyone has to get paid,” he says, standing in front of his company’ office. “Because remember, it’s a chance you take. If we’re going to smuggle it, it’s going to be expensive.” Drivers charge as much as $500 to take a flask of mercury, which contains 34.5 kg of the quicksilver, across the river at Lethem. They use informal crossings to avoid the Brazilian federal police. Once it’s safely in Brazil, cab drivers carry the mercury to the local market in the city of Boa Vista.Sometimes private cars take it a few hours further north, into Venezuela.`}</p>
    <blockquote>
      <p parentName="blockquote">{`“Because remember, it’s a chance you take. If we’re going to smuggle it, it’s going to be expensive.”`}</p>
    </blockquote>
    <p>{`The combi-vans also supply mining towns in Guyana’s interior. A steady trickle of vans, piled high with small mining equipment and food supplies and packed with passengers jockeying for window space, make the eight-hour trip to Mahdia.`}</p>
    <p>{`The mining enclave was only declared a municipality in 2018. Most of the buildings in the small town center are made of wood. There are a few nightclubs and a gold shop on nearly every street. The lush jungle around the village is marked by dried out creeks and massive ochre-colored pits, as if a meteorite had fragmented just above the town and left craters throughout the surrounding area.`}</p>
    <Video url="https://youtube.com/embed/ExEVqQRdPqk" mdxType="Video" />
    <p>{`In one of these jungle pits, a young man holds on tight to a water hose as high-pressure water bursts out of the nozzle. He is called a “jetman.” He washes the gold-containing slurry into muddy pools, where a “pitman” works chest-deep in the water removing stones and other large pieces of rubbish. From there the “marrackman” uses a suction pump to funnel the slurry from the pools into the sluice box, yet another contraption that separates coarse gold pieces from other suspended particles by weight.`}</p>
    <p>{`Under a structure made with wooden sticks and a plastic sheet sits the operation's overseer Kennard Williams*. His job is to trap the tinyflecks of gold that remain in the muddy slurry. "The gold is like powder. It's so fine, without the silver, you can't trap it," he says, sprinkling the silvery liquid over a thin amalgam plate.`}</p>
    <Gallery mdxType="Gallery">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAQFA//EABYBAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAABSHts2eUQ/8QAGRAAAwEBAQAAAAAAAAAAAAAAAAECAxES/9oACAEBAAEFAvQm2cRnlJMqXw//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAaEAACAgMAAAAAAAAAAAAAAAABEQAQAiFR/9oACAEBAAY/AogHNg1ku1//xAAaEAEBAQADAQAAAAAAAAAAAAABABEhMVFh/9oACAEBAAE/IdGYafJXltkOgyrLHqQ3/9oADAMBAAIAAwAAABBvL//EABYRAQEBAAAAAAAAAAAAAAAAAAARIf/aAAgBAwEBPxDVf//EABYRAAMAAAAAAAAAAAAAAAAAAAABEf/aAAgBAgEBPxBRkP/EABwQAQEAAgIDAAAAAAAAAAAAAAERACExUUHh8P/aAAgBAQABPxBYq3nYwylIgfTFJ0xPWWVoG07mCU7secVrzn//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/32b916684070e2673394ef92d5ad98cd/e93cc/slider-2-1.webp 300w", "/static/32b916684070e2673394ef92d5ad98cd/b0544/slider-2-1.webp 600w", "/static/32b916684070e2673394ef92d5ad98cd/68fc1/slider-2-1.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/32b916684070e2673394ef92d5ad98cd/73b64/slider-2-1.jpg 300w", "/static/32b916684070e2673394ef92d5ad98cd/3ad8d/slider-2-1.jpg 600w", "/static/32b916684070e2673394ef92d5ad98cd/6c738/slider-2-1.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/32b916684070e2673394ef92d5ad98cd/6c738/slider-2-1.jpg",
              "alt": "Alt",
              "title": "Alt",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{` `}<em parentName="p">{`Most of the miners in and around Mahdia are not in it for the riches; they are subsistence miners.`}</em></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEAv/EABYBAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAABibE/N2ID/8QAGhABAAMAAwAAAAAAAAAAAAAAAQACEQMSI//aAAgBAQABBQLUhy4+cLQoJc62/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGhAAAgIDAAAAAAAAAAAAAAAAAAEQMREhkf/aAAgBAQAGPwKzD2i30c//xAAZEAADAQEBAAAAAAAAAAAAAAAAAREhMVH/2gAIAQEAAT8hUSceinczHeD0ZgsjIR//2gAMAwEAAgADAAAAEAcP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQIBAT8Qp//EABwQAQEAAgMBAQAAAAAAAAAAAAERACExQZFhwf/aAAgBAQABPxDSRSdr9uAJpgR47zYhePzFiWyc8Zc6NHWC04g7M//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/1ffd1dfec0074983e29717a877469f81/e93cc/slider-2-2.webp 300w", "/static/1ffd1dfec0074983e29717a877469f81/b0544/slider-2-2.webp 600w", "/static/1ffd1dfec0074983e29717a877469f81/68fc1/slider-2-2.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/1ffd1dfec0074983e29717a877469f81/73b64/slider-2-2.jpg 300w", "/static/1ffd1dfec0074983e29717a877469f81/3ad8d/slider-2-2.jpg 600w", "/static/1ffd1dfec0074983e29717a877469f81/6c738/slider-2-2.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/1ffd1dfec0074983e29717a877469f81/6c738/slider-2-2.jpg",
              "alt": "Alt",
              "title": "Alt",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQBAwX/xAAVAQEBAAAAAAAAAAAAAAAAAAABA//aAAwDAQACEAMQAAABlrKuhRsUB//EABsQAAEEAwAAAAAAAAAAAAAAAAEAAhIiERMh/9oACAEBAAEFAttpDEgmdIsQ8r//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAwEBPwGq/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAES/9oACAECAQE/AbGX/8QAGRAAAwADAAAAAAAAAAAAAAAAARARACEx/9oACAEBAAY/AhuR9wr/xAAYEAADAQEAAAAAAAAAAAAAAAAAAREhMf/aAAgBAQABPyF1KCV70lfRu3S2usEo41yGrOH/2gAMAwEAAgADAAAAEHzv/8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQMBAT8QEz//xAAVEQEBAAAAAAAAAAAAAAAAAAABAP/aAAgBAgEBPxARmf/EAB0QAQADAAMAAwAAAAAAAAAAAAEAESExQWGB0fD/2gAIAQEAAT8QQ1xB8FewOZx2to9idijp1kCgE2J+4lwrkeE+4qgNVvc//9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/98d8579b3b4b190ef0ced63a1fd7902d/e93cc/slider-2-3.webp 300w", "/static/98d8579b3b4b190ef0ced63a1fd7902d/b0544/slider-2-3.webp 600w", "/static/98d8579b3b4b190ef0ced63a1fd7902d/68fc1/slider-2-3.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/98d8579b3b4b190ef0ced63a1fd7902d/73b64/slider-2-3.jpg 300w", "/static/98d8579b3b4b190ef0ced63a1fd7902d/3ad8d/slider-2-3.jpg 600w", "/static/98d8579b3b4b190ef0ced63a1fd7902d/6c738/slider-2-3.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/98d8579b3b4b190ef0ced63a1fd7902d/6c738/slider-2-3.jpg",
              "alt": "Alt",
              "title": "Alt",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEAf/EABUBAQEAAAAAAAAAAAAAAAAAAAID/9oADAMBAAIQAxAAAAGTEvCoLCS//8QAGhABAAMAAwAAAAAAAAAAAAAAAQACEhETIf/aAAgBAQABBQLt9FZXPBfNqOkQn//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAEDAQE/AUf/xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAgEBPwFX/8QAGxAAAQQDAAAAAAAAAAAAAAAAAQAQESExQZH/2gAIAQEABj8Cxbb61hQAv//EABsQAAMBAQADAAAAAAAAAAAAAAABESFBMZGx/9oACAEBAAE/Ia9TCi7nYUAxzXn4Jx63zBTCkj//2gAMAwEAAgADAAAAEKM//8QAFhEBAQEAAAAAAAAAAAAAAAAAAAEh/9oACAEDAQE/EJqH/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQARIf/aAAgBAgEBPxBw7J2//8QAGxABAAMBAQEBAAAAAAAAAAAAAQARIVExYbH/2gAIAQEAAT8QASpbZG2HhChbbX5gKrZ6Jpelpa10OzWSgrb/AFDLngQQT//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/bab697cd3705c541f790ac5c9ec33e2e/e93cc/slider-2-4.webp 300w", "/static/bab697cd3705c541f790ac5c9ec33e2e/b0544/slider-2-4.webp 600w", "/static/bab697cd3705c541f790ac5c9ec33e2e/68fc1/slider-2-4.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/bab697cd3705c541f790ac5c9ec33e2e/73b64/slider-2-4.jpg 300w", "/static/bab697cd3705c541f790ac5c9ec33e2e/3ad8d/slider-2-4.jpg 600w", "/static/bab697cd3705c541f790ac5c9ec33e2e/6c738/slider-2-4.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/bab697cd3705c541f790ac5c9ec33e2e/6c738/slider-2-4.jpg",
              "alt": "Alt",
              "title": "Alt",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAQFAQP/xAAWAQEBAQAAAAAAAAAAAAAAAAABAAL/2gAMAwEAAhADEAAAAVdR6YaRMG//xAAaEAACAgMAAAAAAAAAAAAAAAACAwABBBAR/9oACAEBAAEFAhe2W5kDIMBuc1//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAaEAACAgMAAAAAAAAAAAAAAAAAAQIRICFB/9oACAEBAAY/AulOWik1h//EABsQAQADAAMBAAAAAAAAAAAAAAEAESExQWGB/9oACAEBAAE/Iem/TEgm3ssUnO7LKbAGbDZ//9oADAMBAAIAAwAAABB83//EABYRAQEBAAAAAAAAAAAAAAAAAAABIf/aAAgBAwEBPxCMf//EABgRAAIDAAAAAAAAAAAAAAAAAABRAREh/9oACAECAQE/ENZUs//EABwQAQADAAIDAAAAAAAAAAAAAAEAESExQWFxgf/aAAgBAQABPxAjWTFBK+xluwfKuGuYSsh7RfVxlDNrIsAWDazKzN9z/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/c2aee869a4b62f10a8ed1ace82c61531/e93cc/slider-2-5.webp 300w", "/static/c2aee869a4b62f10a8ed1ace82c61531/b0544/slider-2-5.webp 600w", "/static/c2aee869a4b62f10a8ed1ace82c61531/68fc1/slider-2-5.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/c2aee869a4b62f10a8ed1ace82c61531/73b64/slider-2-5.jpg 300w", "/static/c2aee869a4b62f10a8ed1ace82c61531/3ad8d/slider-2-5.jpg 600w", "/static/c2aee869a4b62f10a8ed1ace82c61531/6c738/slider-2-5.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/c2aee869a4b62f10a8ed1ace82c61531/6c738/slider-2-5.jpg",
              "alt": "Alt",
              "title": "Alt",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
    </Gallery>
    <p>{`A generator kicks on and a muddy stream of water runs over the plate. Water and silt run through it while the amalgam is trapped on top. Occasionally, Williams pours more mercury over the plate, sometimes going through more than two pounds a day. The whole process on average takes a few days and multiple amalgam plates are used.`}</p>
    <Video url="https://youtube.com/embed/dOcQdbrynXo" mdxType="Video" />
    <p>{`Most of the miners in and around Mahdia are not in it for the riches; they are subsistence miners. Even after long days of backbreaking work, the miners are still barely able to support their families. Dexton Grant, an experienced miner who recently began working for Williams,carries heavy wooden planks on his shoulders as he slogs between the contaminated pits in the mine. There are few job opportunities in Mahdia outside the mining sector, according to Grant. He‘s grateful for the opportunity to put food on his family’s table.`}</p>
    <p>{`The lack of educational opportunities and state investments in the region has condemned generations of villagers to a life in the muddy pits. Grant hopes that his children will have the chance to dedicate their lives to something else: “I’m in the mines to get this gold,” he says, and “to let them go to school.”`}</p>
    <Video url="https://youtube.com/embed/4SJe-illSZw" mdxType="Video" />
    <p>{`Small-scale miners are usually paid based on their production; the less gold they’re able to extract, the less they get paid. If mercury cannot be used anymore, Grant says it will be his exit from the sector. “It serves no purpose without the mercury, because it traps the gold much more,” he says. He’s more worried about his economic wellbeing than about his health.`}</p>
    <p>{`There is no safe level of mercury exposure, according to experts, but Grant claims that mercury has not affected his body. It’s a belief that’s shared by many miners in Guyana.`}</p>
    <Video url="https://youtube.com/embed/noKstuzu1FQ" mdxType="Video" />
    <p>{`Harry Casey*, a Mahdia local who runs a mining project himself nearby, does not understand why people warn him about the mercury use. “It still gets me baffled, why is mercury so dangerous?” he asks out loud, driving his Toyota pickup truck through the jungle between mines. He smiles as he recalls playing with mercury as a child on visits to his father’s mine. His father used “buckets of mercury” as a miner and is still alive today. “My father is 84,” he says. “He made 17 children with my mother.”`}</p>
    <p>{`Misconceptions about the health risks of mining with mercury are a serious issue in Guyana. But miners themselves are not the group that’s most affected by mercury contamination. In its liquid form, as miners use it, mercury is less of a risk to human health than in its gaseous form.`}</p>
    <hr></hr>
    <p>{`Gold shop workers, who burn the mercury off the amalgam, are most exposed to the toxic vapors. In the middle of one of the villages in the Guyanese goldfields of the interior, Leroy Persaud* chuckles nervously from behind his big desk in his gold shop, a one-story wood structure protected by solid metal bars. In 2013, after almost two years of buying gold from the mines and miners, burning the mercury off the amalgam sponges, and melting the
gold into small ingots, his health began to deteriorate.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGQAAAQUAAAAAAAAAAAAAAAAAAAECAwQF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAABylrTAND/xAAbEAABBAMAAAAAAAAAAAAAAAACAAEDERASIv/aAAgBAQABBQLaxaJzHtsBKQK1/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGhAAAgIDAAAAAAAAAAAAAAAAAREAEAJBUf/aAAgBAQAGPwIPXYconSBr/8QAGxAAAwACAwAAAAAAAAAAAAAAAAERMUEhUaH/2gAIAQEAAT8haIRcArEcejSfjsyJ9rwo/9oADAMBAAIAAwAAABCT7//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAgEBPxBkf//EAB0QAQADAAEFAAAAAAAAAAAAAAEAETEhQVFhcaH/2gAIAQEAAT8QQzO7NHavsSoyOhfUpkQVVo8RKbGvECC7F3Kquq8s/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/8a9bacc3b3e21b7894c3dd14d14d489b/e93cc/DSC07015.webp 300w", "/static/8a9bacc3b3e21b7894c3dd14d14d489b/b0544/DSC07015.webp 600w", "/static/8a9bacc3b3e21b7894c3dd14d14d489b/68fc1/DSC07015.webp 1200w", "/static/8a9bacc3b3e21b7894c3dd14d14d489b/0b978/DSC07015.webp 1400w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/webp"
          }}></source>{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/8a9bacc3b3e21b7894c3dd14d14d489b/73b64/DSC07015.jpg 300w", "/static/8a9bacc3b3e21b7894c3dd14d14d489b/3ad8d/DSC07015.jpg 600w", "/static/8a9bacc3b3e21b7894c3dd14d14d489b/6c738/DSC07015.jpg 1200w", "/static/8a9bacc3b3e21b7894c3dd14d14d489b/22cab/DSC07015.jpg 1400w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/jpeg"
          }}></source>{`
        `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/8a9bacc3b3e21b7894c3dd14d14d489b/6c738/DSC07015.jpg",
            "alt": "The amalgamed gold",
            "title": "The amalgamed gold",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
      `}</picture>{`
    `}</span>{` `}<em parentName="p">{`The amalgamed gold`}</em></p>
    <p>{`“Things started to get out of hand with me,” Persaud explains. Later that year, he started waking up with headaches, suffering from diarrhea and vomiting, and losing his sight. He also got a temper. “I ended up hitting my girlfriend,” he says. “I never used to do that.” In 2013, Persaud went to the local clinic in his village, where he tested negative for malaria and dengue. His symptoms continued to worsen, and after one particularly traumatic incident, in which Persaud began shaking uncontrollably and nearly lost his sight completely,his local doctors sent him to the capital. “I thought I was going to die,” he recalls.`}</p>
    <p>{`At Eureka Laboratories in Georgetown, the only facility then equipped to test for mercury poisoning, his blood tests showed his mercury levels were 160 nanograms per milliliter (ng/mL), at least 10 times the normal`}</p>
    <blockquote>
      <p parentName="blockquote">{`THE DOCTOR IN BRAZIL `}{`[TOLD ME]`}{`, ‘QUIT THE WORK IF YOU WANT TO LIVE.’ BUT THIS IS THE ONLY WORK I KNOW HOW TO DO TO SURVIVE AND `}{`[PROVIDE]`}{` FOR MY FAMILY`}</p>
    </blockquote>
    <p>{`levels. The doctors advised him to leave his work and the mining region, but there was no treatment available in Guyana. An ex-girlfriend urged him to go to a hospital in Manaus, Brazil, according to Persaud, but the treatment was expensive and he was short on cash. To raise the 2.5 million GYD he needed for the trip and treatment, he went back to work in his gold shop. When he finally reached the Saint Julia hospital in Manaus, the doctors did not understand how he was still alive: the latest tests revealed that the mercury levels in his blood had climbed to 320ng/mL. “The doctor in Brazil `}{`[told me]`}{`, ‘Quit the work if you want to live.’ But this is the only work I know how to do to survive and `}{`[provide]`}{` for my family. But I came back prepared.”`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMCBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAC/9oADAMBAAIQAxAAAAHjyZLIJf/EABoQAQABBQAAAAAAAAAAAAAAAAECABASITH/2gAIAQEAAQUC7aM8QUXbX//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAgEBPwFkf//EABYQAQEBAAAAAAAAAAAAAAAAAAEQMf/aAAgBAQAGPwKibf/EABsQAQEBAAIDAAAAAAAAAAAAAAERABAhMUFR/9oACAEBAAE/IWe7fgZ6o4YRytIPCZ0Xj//aAAwDAQACAAMAAAAQMC//xAAWEQEBAQAAAAAAAAAAAAAAAAABERD/2gAIAQMBAT8QIEz/xAAWEQEBAQAAAAAAAAAAAAAAAAABABH/2gAIAQIBAT8QdXZa/8QAGRABAAMBAQAAAAAAAAAAAAAAAQARITEQ/9oACAEBAAE/ENQ4csXAELY4MrNtvs3poiki9Ut+f//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/e3e966b96fd870b528c8c81eb7dfa065/e93cc/DSC07030.webp 300w", "/static/e3e966b96fd870b528c8c81eb7dfa065/b0544/DSC07030.webp 600w", "/static/e3e966b96fd870b528c8c81eb7dfa065/68fc1/DSC07030.webp 1200w", "/static/e3e966b96fd870b528c8c81eb7dfa065/4b075/DSC07030.webp 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/webp"
          }}></source>{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/e3e966b96fd870b528c8c81eb7dfa065/73b64/DSC07030.jpg 300w", "/static/e3e966b96fd870b528c8c81eb7dfa065/3ad8d/DSC07030.jpg 600w", "/static/e3e966b96fd870b528c8c81eb7dfa065/6c738/DSC07030.jpg 1200w", "/static/e3e966b96fd870b528c8c81eb7dfa065/38b44/DSC07030.jpg 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/jpeg"
          }}></source>{`
        `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/e3e966b96fd870b528c8c81eb7dfa065/6c738/DSC07030.jpg",
            "alt": "Burning the mercury off and smelting the gold into small ingots",
            "title": "Burning the mercury off and smelting the gold into small ingots",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
      `}</picture>{`
    `}</span>{` `}<em parentName="p">{`Burning the mercury off and smelting the gold into small ingots`}</em></p>
    <p>{`Persaud continues to burn the toxic amalgam that miners sell to him. But now, fully suited up, with a mask and an extractor fan, Persaud argues that his activity is “90 percent safe.” The treatment he brought from Brazil, in combination with a healthy, vitamin-rich diet and an exercise routine to sweat the mercury out of his pores, helped him to get his levels back to 10ng/mL. “You don’t really see it, but you smell it,” Persaud laughs. “The scent I used to have is not there anymore.”`}</p>
    <hr></hr>
    <p>{`Today, seven years after Persaud made the trek to the capital, miners who want to get tested for mercury in Guyana still need to go to the Eureka Laboratories on Thomas Street in Georgetown. Inside, Dr. Rhonda Archer sees patients at the hospital bed in her office, separated from her desk by a light blue curtain.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "150%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAeABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMEAQL/xAAVAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAABT1kENo0mUprGtOAP/8QAGxAAAgMBAQEAAAAAAAAAAAAAAAECAxESMTP/2gAIAQEAAQUCksLJxZyi5nTk6vm5C2T1jWjWHh//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAVEQEBAAAAAAAAAAAAAAAAAAABIP/aAAgBAgEBPwEj/8QAHhAAAgEDBQAAAAAAAAAAAAAAAAEREDFBAhIhUWH/2gAIAQEABj8CnUxbreHAukOM4QpGmTkvSFT/xAAdEAEAAgICAwAAAAAAAAAAAAABABEhMVFxQWGB/9oACAEBAAE/ISNwNYhAAjT49yzOkEvVpdcwBDEiwAOyYKmsXAC5TghxD5CTZqANR6l6T//aAAwDAQACAAMAAAAQYx8C/8QAGBEAAwEBAAAAAAAAAAAAAAAAAAEhEBH/2gAIAQMBAT8QThwcz//EABcRAQEBAQAAAAAAAAAAAAAAAAEAESH/2gAIAQIBAT8QC9Zcbcsv/8QAHRABAQACAgMBAAAAAAAAAAAAAREAITFBUWFxof/aAAgBAQABPxAXfBGlzQEuNfH4wMBUaQ5xxxbjtGfNmTvrHgcmiSgbguCEO6hS9YkQ0hqDAhPQ2wg5s5t/MZ0RPC4pd53XP//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/33838494518738afe5f24b53cd7c7d9a/e93cc/DSC07416.webp 300w", "/static/33838494518738afe5f24b53cd7c7d9a/b0544/DSC07416.webp 600w", "/static/33838494518738afe5f24b53cd7c7d9a/8d2ea/DSC07416.webp 800w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "type": "image/webp"
          }}></source>{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/33838494518738afe5f24b53cd7c7d9a/73b64/DSC07416.jpg 300w", "/static/33838494518738afe5f24b53cd7c7d9a/3ad8d/DSC07416.jpg 600w", "/static/33838494518738afe5f24b53cd7c7d9a/c60e9/DSC07416.jpg 800w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "type": "image/jpeg"
          }}></source>{`
        `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/33838494518738afe5f24b53cd7c7d9a/c60e9/DSC07416.jpg",
            "alt": "Miners burning off the mercury in a gold mine",
            "title": "Miners burning off the mercury in a gold mine",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
      `}</picture>{`
    `}</span>{` `}<em parentName="p">{`Miners burning off the mercury in a gold mine.`}</em></p>
    <p>{`Archer is an expert on mercury poisoning. She runs through a long list of its potential health effects: respiratory illnesses, neurological problems, damage to the liver, kidneys, and immune systems, to name a few. But she couldn’t do much for her patients in Guyana until treatment became available in 2018. That year, fo ur employees of the Guyana Geology and Mines Commission died over a six-month period at a lab run by the Guyana Gold Board, where mercury was burned off raw gold. Officials blamed mercury fumes. The lab was subsequently shut down, and since then, mercury treatment beca me available, mainly imported from the U.S.`}</p>
    <p>{`Before that, only 5 to 10 people a year came to see Archer in Georgetown to get a mercury test; most people didn’t bother, knowing that medical help was not available. Since 2018, the number of miners seeking medical attention from her has doubled, but that still only amounts to 10 to 20 people a year.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Since 2018, the number of miners seeking medical attention from her has doubled, but that still only amounts to 10 to 20 people a year.`}</p>
    </blockquote>
    <p>{`Meanwhile, there are as many as 20,000 small-scale miners in Guyana, and the vast majority work with mercury on a daily basis. Archer worries that too few miners are seeking help for mercury poisoning in the capital even now that treatment is available in Guyana.`}</p>
    <p>{`The test for mercury is kind of costly in comparison to a person’s wage in this country,” Archer says. “That seems to be a deterrent.” Blood testing for mercury poisoning, and the battery of other tests required to determine how much damage it’s caused throughout the body, can cost over 40,000 GYD (equivalent to nearly $200 USD), or more than half the monthly wages of many small-scale miners. The subsequent treatment costs an additional 25,000 GYD, on average.`}</p>
    <hr></hr>
    <p>{`The morning prayer crackles through the speakers of the local mosque in Corriverton at 5am. It's still dark, save for a few flickering streetlights. Suddenly the sound from the mosque stops, as a blackout envelops the village. With the first sunlight over the Corentyne river, a sleepy smuggler shows up in front of a house and walks through the entrance to the back of a courtyard to open the metal gates that lead to the river. It is time to go.`}</p>
    <Video url="https://youtube.com/embed/eOLHxmi8WV4" mdxType="Video" />
    <p>{`The engine of a go-fast boat roars to life next to the nearby pier. At a short distance, an Indian man wades waist deep through the water and washes up for his morning prayer; he pays no attention to what happens next to him. Contraband is usually moved at night, ferried across the river into Suriname at informal crossings known as “the backtrack.” That is, if the changing tides from the nearby sea allow for a safe passage over the Corentyne river. Last night the tide was too low, but the captain is not worried. “`}{`[Contraband]`}{` never gets seized on the boat on the water,” he says, steering away from the riverbank toward Suriname.`}</p>
    <p>{`“A lot of people make money out of the backtrack,” he adds. The mercury, which is stored in heavy metal flasks, each worth as much as $8,000 USD, is taken across the river and into Suriname at various points along the coast. Smugglers sometimes pack as many as six or seven flasks onto each boat.`}</p>
    <p>{`Mercury trafficking is a steady, if not lucrative business for captains, who charge tens of thousands of Guyanese dollars for each trip to Suriname, according to Showtime. “It’s illegal over there, but it’s legal over here, so you’re gonna find that the demand is mad-high,” says Showtime, hammering away beers and rum the night before in Corriverton. Small quantities are moved at a time, and compared to moving drugs like cocaine over the same route, the legal consequences are much smaller. But it’s still a risk.`}</p>
    <p>{`“If you get caught by the police in Suriname, you go to jail,” says the captain, after turning off the loud engine in the middle of the Corentyne River. But if all goes well, he adds, “the guy who purchased it will be on the other side waiting, with a taxi or his car or whatsoever. When we drop it off, he collects it.”`}</p>
    <p>{`Trafficking routes are as fluid as the mercury itself. They quickly change depending on where law enforcement focuses its attention and on the ever changing supply and demand mechanisms that dictate the black market in minerals. Until recently, this exact spot was an active backtrack for mercury trafficking. A new control post on the Surinamese side of the river has diverted the flow of mercury. Now flasks are taken across further upstream, where the riverbanks are covered by dense jungle.`}</p>
    <p>{`“It’s still going to Suriname,” he says, but now it’s taken across “without
the people’s eyes.”`}</p>
    <p><em parentName="p">{`*`}{`Some names have been changed to protect the identities of interviewees.`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      